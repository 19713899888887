<template>
  <div class="content">
    <div class="merchants">
      <div class="title">
        <span></span>
        <h2>合作范围</h2>
        <!-- <span v-show="lang == '1'">products</span>
        <span v-show="lang == '0'">三大行业解决方案</span>
        <span v-show="lang == '1'">solutions</span> -->
        <span>三大行业解决方案</span>
      </div>
    </div>
    <!--  智慧产品 -->
    <div class="Wisdom">
      <div class="item">
        <img src="../assets/merchants/merchants1.png" alt="智慧水利图标" />
        <p class="item_p1">智慧水利</p>
        <p class="item_p2">解决方案及相关子系统,硬件设备</p>
        <!-- <button @click="gotoCase">了解详情</button> -->
        <a href="#/programme?name=%23content&id=0">
          <button>了解详情</button>
        </a>
      </div>
      <div class="item">
        <img src="../assets/merchants/merchants2.png" alt="智慧城管图标" />
        <p class="item_p1">智慧城管</p>
        <p class="item_p2">解决方案及相关子系统,硬件设备</p>
        <!-- <button @click="gotoCase1">了解详情</button> -->
        <a href="#/programme?name=%23content&id=1">
          <button>了解详情</button>
        </a>
      </div>
      <div class="item">
        <img src="../assets/merchants/merchants3.png" alt="智慧农业图标" />
        <p class="item_p1">智慧农业</p>
        <p class="item_p2">解决方案及相关子系统,硬件设备</p>
        <!-- <button @click="gotoCase2">了解详情</button> -->
        <a href="#/programme?name=%23content&id=2">
          <button>了解详情</button>
        </a>
      </div>
    </div>
    <!-- 合作条件 -->
    <div id="Conditions" style="height: 10px"></div>
    <div class="Conditions">
      <div class="title">
        <span></span>
        <h2>合作条件</h2>
      </div>
      <div class="content">
        <div class="content_1">
          <div class="content_2">
            <img src="../assets/merchants/01.png" alt="序号小图片" />
            <span
              >遵守国家法律法规，具有合法资格的法人或自然人，具备良好的资信状况；</span
            >
          </div>
          <div style="border: 1px dashed #004aa1; margin-top: 15px"></div>
        </div>
        <div class="content_1">
          <div class="content_2">
            <img src="../assets/merchants/02.png" alt="序号小图片" />
            <span>
              区域授权合作伙伴，负责的区域可以是片区、省或市，授权的合作伙伴只能对授权区域的项目进行产品销售和提供技术服务；</span
            >
          </div>
          <div style="border: 1px dashed #004aa1; margin-top: 15px"></div>
        </div>
        <div class="content_1">
          <div class="content_2">
            <img src="../assets/merchants/03.png" alt="序号小图片" />
            <span
              >授权的合作伙伴需完成区域分配的业绩指标，并遵守旭瑞智能的市场管理要求；</span
            >
          </div>
          <div style="border: 1px dashed #004aa1; margin-top: 15px"></div>
        </div>
        <div class="content_1">
          <div class="content_2">
            <img src="../assets/merchants/04.png" alt="" />
            <span
              >具有一定的客户渠道及资源，或较强的销售团队和技术团队者优先考虑；</span
            >
          </div>
          <div style="border: 1px dashed #004aa1; margin-top: 15px"></div>
        </div>
        <div class="content_1">
          <div class="content_2">
            <img src="../assets/merchants/05.png" alt="序号小图片" />
            <span>不可同时经销与旭瑞智能同类型的产品。</span>
          </div>
          <div style="border: 1px dashed #004aa1; margin-top: 15px"></div>
        </div>
      </div>
    </div>
    <!-- 加盟政策 -->
    <div id="Policies"></div>
    <div class="Policies">
      <div class="title">
        <span></span>
        <h2>合作政策</h2>
      </div>
      <div class="content">
        <div class="content_1">
          <p>01</p>
          <p>零费用合作</p>
          <p>不允许以任何名义收取项目加盟费、品牌管理费</p>
        </div>
        <div class="content_1">
          <p>02</p>
          <p>独家代理制</p>
          <p>每一个区域，发展唯一的区域合作伙伴</p>
        </div>
        <div class="content_1">
          <p>03</p>
          <p>资质支持</p>
          <p>使用唯一品牌旭瑞智能，并向合作伙伴提供资质证书支持</p>
        </div>
        <div class="content_1">
          <p>04</p>
          <p>区域保护</p>
          <p>
            公司不直接在双方合作区域内销售相关产品，通过任何渠道获取到的客户都将转给当地的合作伙伴
          </p>
        </div>
        <div class="content_1">
          <p>05</p>
          <p>长期合作</p>
          <p>各地市的合作伙伴，合同期内完成任务额，下一年继续续签或免费升级</p>
        </div>
      </div>
    </div>
    <!-- 背景 -->
    <div class="banner" id="banner">
      <div id="content_title"></div>
      <div class="content">
        <img
          src="../assets/merchants/banner-left.png"
          alt="合作支持左边背景图"
        />
        <img
          src="../assets/merchants/banner-right.png"
          width="779px"
          alt="合作支持右边背景图"
        />
        <div class="content_title">
          <div class="title">
            <span></span>
            <h2>合作支持</h2>
          </div>
          <div class="banner">
            <img src="../assets/merchants/banner1.png" alt="支持背景图" />
            <img src="../assets/merchants/banner2.png" alt="图标背景图" />
            <p class="banner_p1">八大支持</p>
            <p class="banner_p2">实现公司与合作伙伴的真正共赢</p>
            <p class="banner_p3">招投标支持</p>
            <p class="banner_p4">区域保护</p>
            <p class="banner_p5">调换货支持</p>
            <p class="banner_p6">物流支持</p>
            <p class="banner_p7">物料支持</p>
            <p class="banner_p8">技术支持</p>
            <p class="banner_p9">市场指导</p>
            <p class="banner_p10">培训支持</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 优势 -->
    <div id="advantage" class="advantage_content"></div>

    <div class="advantage">
      <div class="title">
        <span></span>
        <h2>合作优势</h2>
      </div>
      <div style="margin: 66px 0 160px 0">
        <div class="item">
          <p class="item_p">01</p>
          <div class="item_right">
            <p>项目优势,大势所趋</p>
            <p>
              智慧行业前景好，市场需求巨大，数字化、智能化、智慧化将成为各行各业升级转型的重要抓手。
            </p>
          </div>
        </div>
        <div class="item">
          <p class="item_p">02</p>
          <div class="item_right">
            <!-- <div id="advantage" class="advantage_content"></div> -->

            <p>灵活多样的销售模式</p>
            <p>
              产品种类丰富，可单品销售，也可整套系统输出，还能为客户定制解决方案，可以满足不同用户需求及采购方式。
            </p>
          </div>
        </div>
        <div class="item">
          <p class="item_p">03</p>
          <div class="item_right">
            <p>优越的政策支持</p>
            <p>
              严格的区域保护政策、低门槛准入政策，帮扶式培训政策，高效的市场支持，帮助合作伙伴快速成长。
            </p>
          </div>
        </div>
        <div class="item">
          <p class="item_p">04</p>
          <div class="item_right">
            <p>客户的信赖之选</p>
            <p>
              十余年技术沉淀与口碑积累，多项技术成果获得行业认可，且拥有丰富的成功案例，是广大客户的信赖之选。
            </p>
          </div>
        </div>
        <div id="Process"></div>
      </div>
    </div>

    <!-- 流程 -->
    <div class="Process">
      <div class="title">
        <span></span>
        <h2>合作流程</h2>
      </div>
      <div class="content">
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process1.png"
            alt="了解图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n1.png"
            alt="序号图标"
          />
          <div class="item_div">
            <div class="item_div1"></div>
            <span class="item_span"></span>
          </div>
          <p>了解旭瑞智能</p>
        </div>
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process2.png"
            alt="政策图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n2.png"
            alt="序号图标"
          />
          <div class="item_div">
            <div class="item_div1"></div>
            <span class="item_span"></span>
          </div>
          <p>了解合作政策</p>
        </div>
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process3.png"
            alt="意向图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n3.png"
            alt="序号图标"
          />
          <div class="item_div">
            <div class="item_div1"></div>
            <span class="item_span"></span>
          </div>
          <p>提出合作意向</p>
        </div>
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process4.png"
            alt="洽谈图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n4.png"
            alt="序号图标"
          />
          <div class="item_div">
            <div class="item_div1"></div>
            <span class="item_span"></span>
          </div>
          <p>洽谈合作意向</p>
        </div>
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process5.png"
            alt="协议图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n5.png"
            alt="序号图标"
          />
          <div class="item_div">
            <div class="item_div1"></div>
            <span class="item_span"></span>
          </div>
          <p>签署合作协议</p>
        </div>
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process6.png"
            alt="指导图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n6.png"
            alt="序号图标"
          />
          <div class="item_div">
            <div class="item_div1" style="width: 157px"></div>
            <span class="item_span"></span>
          </div>
          <p>全面培训指导</p>
        </div>
        <div class="item">
          <img
            width="47px"
            height="47px"
            src="../assets/merchants/Process7.png"
            alt="合作图标"
          />
          <img
            width="23px"
            height="23px"
            src="../assets/merchants/n7.png"
            alt="序号图标"
          />
          <p>开展合作,共享财富</p>
        </div>
      </div>
    </div>
    <!-- 商机 -->

    <div class="opportunity">
      <div id="opportunity" class="content_sj"></div>
      <div class="item">
        <img src="../assets/merchants/banner_bottom.png" alt="商机大背景图" />
        <div class="content">
          <div class="title">
            <span></span>
            <h2>请开启合作共赢之旅</h2>
          </div>
          <div class="form">
            <div class="name">
              <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="auto"
              >
                <div class="name_email" style="display: flex">
                  <el-form-item label="" prop="fbName">
                    <!-- <p v-show="lang == '0'">
                      姓名<span style="color: red">*</span>
                    </p>
                    <p v-show="lang == '1'">name：</p> -->
                    <p>姓名<span style="color: red">*</span></p>
                    <el-input
                      v-model="form.fbName"
                      :placeholder="lang == 0 ? '姓名' : '姓名'"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="fbPhone">
                    <!-- <p v-show="lang == '0'">
                      电话<span style="color: red">*</span>
                    </p>
                    <p v-show="lang == '1'">Telephone：</p> -->
                    <p>电话<span style="color: red">*</span></p>
                    <el-input
                      v-model="form.fbPhone"
                      :placeholder="lang == 0 ? '电话' : '电话'"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div class="city">
              <p>意向合作城市</p>
              <v-distpicker
                @province="onChangeProv"
                @city="onChangeCity"
                @area="onChangeArea"
              ></v-distpicker>
            </div>
          </div>
        </div>
      </div>
      <span class="button" @click="submit">立即提交，免费领取产品资料</span>
      <el-dialog title="提交成功" :visible.sync="dialogVisible" width="30%">
        <span>我们会尽快与您联系！</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onsubmit"> 确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import { mapMutations } from "vuex";
export default {
  components: { VDistpicker },
  metaInfo: {
    title: "商务合作-东营市旭瑞智能科技股份有限公司", // 设置title
    meta: [
      {
        name: "keywords", // 设置关键字
        content:
          "智慧水利，PLC控制柜，闸泵远程控制，智慧城市，智慧城管，智慧水务，城市防汛监测，智慧闸泵，智慧环卫，智慧公厕，智慧园林，智慧执法，智慧渣土，市容秩序提升，智慧工地，智慧道桥，智慧井盖，智慧管网，智慧照明，智慧农业，智能灌溉，水肥一体化，物联网",
      },
      {
        name: "description", // 设置页面描述
        content:
          "旭瑞智能专注于智慧水利、智慧城管、智慧农业及智能化工程等智慧物联网细分领域软硬件产品研发、生产、销售、运营，提供智慧行业整体解决方案。",
      },
    ],
  },
  data() {
    return {
      dialogVisible: false,
      lang: "",
      form: {
        fbName: "",
        fbPhone: "",
        province: "",
        city: "",
        district: "",
      },
      rules: {
        fbName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        fbPhone: {
          required: true,
          pattern: /^1[0-9]{10}$/,
          message: "请输入正确的手机号",
          trigger: "blur",
        },
      },
    };
  },
  updated() {
    if (this.$route.query.name) {
      this.$el.querySelector(this.$route.query.name).scrollIntoView();
    }
  },
   watch: {
    $route(to,form){
     if(to.name == form.name){
       this.$router.go(0)
     }
    }
  },
  mounted() {
    this.lang = localStorage.getItem("newv");
    // console.log(this.form.fbContent);
  },
  methods: {
    ...mapMutations(["setNum"]),
    gotoCase() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 0,
        },
      });
      this.setNum(0);
      // location.reload();
    },
    gotoCase1() {
      // this.$router.push({path:"/case?id=2"})
      // location.reload();
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 1,
        },
      });
      this.setNum(1);
      //  location.reload();
    },
    gotoCase2() {
      // this.$router.push({path:"/case?id=3"})
      // location.reload();
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 2,
        },
      });
      this.setNum(2);
      //  location.reload();
    },
    onsubmit() {
      this.dialogVisible = false;
      this.$router.go(0);
    },
    submit() {
      //  console.log('打印');
      this.$refs.form.validate((valid) => {
        console.log("valid : >>", valid);
        if (valid) {
          this.dialogVisible = true;
          console.log(this.form);
          let data = {
            type: 2,
            province: this.form.province.value,
            city: this.form.city.value,
            district: this.form.district.value,
            fbName: this.form.fbName,
            fbPhone: this.form.fbPhone,
            fbContent: " ",
            // form :this.form
          };
          this.$API.addMessage(data).then((res) => {
            console.log("res : 提交 >>", res);
          });
        }
      });
    },
    onChangeProv(e) {
      console.log("省", e);
      this.form.province = e;
    },
    onChangeCity(e) {
      console.log("市", e);
      this.form.city = e;
    },
    onChangeArea(e) {
      console.log("县", e);
      this.form.district = e;
    },
  },
};
</script>

<style lang='less' scoped>
a {
  color: #fff;
}
h2 {
  font-size: 34px;
}

.content {
  width: 100%;
  .merchants {
    width: 60%;
    margin: 50px auto;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // span:nth-child(1) {
      //   margin-top: 20px;
      //   display: block;
      //   width: 80px;
      //   height: 8px;
      //   background-color: #004aa1;
      // }
      h2 {
        margin-top: 31px;
      }
      span {
        margin-top: 32px;
      }
      // span:nth-child(3) {
      //   width: 160px;
      //   height: 40px;
      //   font-size: 34px;
      //   font-family: Microsoft YaHei;
      //   font-weight: bold;
      //   color: #333333;
      //   margin-top: 31px;
      //   text-align: center;
      // }
      // span:nth-child(4),
      // span:nth-child(5) {
      //   width: 138px;
      //   height: 18px;
      //   font-size: 16px;
      //   font-family: Microsoft YaHei;
      //   font-weight: 400;
      //   color: #333333;
      //   margin-top: 32px;
      // }
    }
  }
  .Wisdom {
    width: 1152px;
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      width: 350px;
      height: 324px;
      text-align: center;
      border-radius: 10px;
      //  border: 1px solid #004696;
      img {
        margin-top: 23px;
      }
      .item_p1 {
        margin-top: 32px;
        font-weight: 700;
        font-size: 18px;
      }
      .item_p2 {
        margin-top: 25px;
      }
      button {
        width: 200px;
        height: 50px;
        cursor: pointer;
        display: block;
        border: 1px solid #fff;
        border-radius: 25px;
        color: white;
        background-color: #004aa1;
        margin: auto;
        margin-top: 42px;
      }
    }
    .item:hover {
      box-shadow: 0px 0px 5px 0px #004aa1;
    }
  }
  .Conditions {
    width: 60%;
    margin: 70px auto;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span:nth-child(1) {
        margin-top: 20px;
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
      }
      span:nth-child(2) {
        width: 160px;
        height: 40px;
        font-size: 34px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-top: 31px;
        text-align: center;
      }
      h2 {
        margin-top: 31px;
      }
    }
    .content {
      margin-top: 54px;
      .content_1 {
        margin-top: 15px;
        .content_2 {
          display: flex;
          img {
          }
          span {
            margin-left: 17px;
            line-height: 29px;
          }
        }
      }
    }
  }
  .Policies {
    width: 60%;
    margin: auto;
    margin-top: 140px;
    text-align: center;
    .title {
      span:nth-child(1) {
        margin-top: 20px;
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        margin: auto;
        margin-bottom: 31px;
      }
      span:nth-child(2) {
        font-size: 34px;
        font-weight: bold;
      }
    }
    .content {
      display: flex;
      margin-bottom: 50px;
      margin-top: 60px;
      .content_1 {
        border: 1px solid #c8c8c8;
        width: 20%;
        p:nth-child(1) {
          font-size: 50px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #004aa1;
          margin-top: 41px;
        }
        p:nth-child(2) {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-top: 22px;
        }
        p:nth-child(3) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          color: #333333;
          margin-top: 30px;
          margin-bottom: 50px;
          padding: 0 5px;
          line-height: 25px;
        }
      }
    }
  }
  .banner {
    margin-top: 142px;
    .content {
      display: flex;
      position: relative;
      img:nth-child(2) {
        margin-left: -2px;
      }
      img:nth-child(1) {
        margin-left: -2px;
      }
      .content_title {
        position: absolute;
        left: 383px;
        top: 90px;
        color: white;
        .title {
          display: flex;
          span:nth-child(1) {
            display: block;
            width: 8px;
            height: 30px;
            background-color: #fff;
            margin-right: 10px;
          }
          h2 {
            font-size: 34px;
            margin-top: -10px;
          }
        }
      }
      .banner {
        margin-left: 70px;
        margin-top: 105px;
        position: absolute;
        img:nth-child(1) {
          // border: 1px dashed #999;
        }
        img:nth-child(2) {
          position: absolute;
          top: 111px;
          left: 149px;
        }
        .banner_p1 {
          color: #004aa1;
          font-size: 23px;
          margin-top: -168px;
          margin-left: 149px;
          font-weight: 700;
        }
        .banner_p2 {
          transform: scale(0.8);
          color: #004aa1;
          margin-top: 5px;
          margin-left: 76px;
        }
        .banner_p3 {
          margin-top: -309px;
          margin-left: 38px;
        }
        .banner_p4 {
          margin-top: -20px;
          margin-left: 259px;
        }
        .banner_p5 {
          margin-top: 102px;
          margin-left: -88px;
        }
        .banner_p6 {
          margin-top: 146px;
          margin-left: -74px;
        }
        .banner_p7 {
          margin-top: 108px;
          margin-left: 51px;
        }
        .banner_p8 {
          margin-top: -21px;
          margin-left: 259px;
        }
        .banner_p9 {
          position: relative;
          top: -315px;
          left: 402px;
        }
        .banner_p10 {
          position: relative;
          top: -168px;
          left: 402px;
        }
      }
    }
  }
  .advantage {
    width: 60%;
    margin: auto;
    margin-top: 120px;

    .title {
      span:nth-child(1) {
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        margin: auto;
        margin-bottom: 10px;
      }
      span:nth-child(2) {
        display: block;
        font-size: 34px;
        font-weight: bold;
        text-align: center;
      }
      h2 {
        text-align: center;
      }
    }
    .item {
      border-radius: 5px;
      border: 1px dashed blue;
      height: 120px;
      border-left: 6px solid #004aa1;
      display: flex;
      margin-bottom: 30px;
      .item_p {
        font-size: 50px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #004aa1;
        line-height: 120px;
        margin-left: 22px;
      }
      .item_right {
        margin-left: 20px;
        p:nth-child(1) {
          font-size: 18px;
          color: #004aa1;
          font-weight: 700;
          margin-top: 35px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .Process {
    width: 60%;
    margin: auto;
    margin-top: -60px;
    margin-bottom: 138px;
    .title {
      span:nth-child(1) {
        display: block;
        width: 80px;
        height: 8px;
        background-color: #004aa1;
        margin: auto;
        margin-bottom: 10px;
      }
      span:nth-child(2) {
        display: block;
        font-size: 34px;
        font-weight: bold;
        text-align: center;
      }
      h2 {
        text-align: center;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 96px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        img:nth-child(2) {
          margin-top: 14px;
          margin-bottom: 25px;
          margin-left: 3px;
        }
        p {
          font-weight: bold;
        }
        .item_div {
          display: flex;
          position: absolute;
          top: 72px;
          left: 64px;
          .item_div1 {
            border-top: 1px dashed #004aa1;
            width: 139px;
          }
          .item_span {
            width: 0;
            height: 0;
            border: 5px solid rgba(0, 0, 0, 0);
            border-left: 5px solid #004aa1;
            margin-top: -4px;
          }
        }
      }
    }
  }
  .opportunity {
    position: relative;
    top: 0;
    margin-bottom: -6px;
    .content_sj {
      position: absolute;
      top: 60px;
      height: 900px;
      width: 100px;
      // display: none;
    }
    .button {
      position: absolute;
      top: 519px;
      left: 751px;
      display: block;
      width: 435px;
      height: 58px;
      background-color: #004aa1;
      color: #fff;
      border-radius: 29px;
      text-align: center;
      line-height: 58px;
      cursor: pointer;
    }
    .item {
      margin: auto;
      img {
        margin-left: -1px;
        top: 0;
        left: 0;
      }
      .content {
        position: absolute;
        top: 140px;
        left: 390px;
        width: 60%;
        text-align: center;
        color: #fff;
        .title {
          span:nth-child(1) {
            display: block;
            width: 80px;
            height: 8px;
            background-color: #fff;
            margin: auto;
            margin-bottom: 10px;
          }
          span:nth-child(2) {
            display: block;
            font-size: 34px;
            font-weight: bold;
            text-align: center;
            margin-top: 31px;
          }
        }
        .form {
          .name {
            .el-form-item {
              width: 47%;
              p {
                text-align: left;
              }
            }
            .el-form-item:nth-child(2) {
              margin-left: 80px;
            }
          }
          .city {
            margin-top: 38px;
            p {
              text-align: left;
              font-size: 14px;
              margin-bottom: 8px;
            }
            /deep/ .distpicker-address-wrapper {
              label {
                select {
                  width: 30%;
                }
              }
              label:nth-child(1) {
                margin-left: -3px;
              }
              label:nth-child(2) {
                margin-left: 54px;
              }
              label:nth-child(3) {
                margin-left: 54px;
              }
            }
          }
        }
      }
    }
  }
}
</style>